<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="850"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Vehicles</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
      >
        <!-- <div class="d-flex justify-space-between align-start my-3">

          <div class="d-flex align-center w-100 justify-space-between">
            <input
              type="text"
              v-model="titles[0]"
              class="form-control h-40px mr-3"
              placeholder="Vehicle number"
            />
            <a
                href="#"
                class="h-40px w-50px btn btn-icon btn-light btn-hover-light btn-sm ml-1"
                @click="() => (null)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <v-icon size="20">mdi-wrap-disabled</v-icon>
                </span>
              </a>
              <a
                  href="#"
                  class="h-40px w-50px btn btn-icon btn-light btn-hover-light btn-sm ml-1"
                  @click="() => null"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <v-icon size="20">mdi-webcam</v-icon>
                </span>
              </a>
              <a
                  href="#"
                  class="h-40px w-50px btn btn-icon btn-light btn-hover-light btn-sm ml-1"
                  @click="() => handleRemove(item)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <v-icon size="20">mdi-delete</v-icon>
                </span>
              </a>
          </div>
        </div> -->
        <div style="max-height: 720px; overflow: auto">
          <template v-for="(item, index) in vehicles">
            <div
              class="d-flex justify-space-between align-start my-3"
              :key="index"
            >
              <div class="d-flex align-center w-100 justify-space-between">
                <input
                  type="text"
                  v-model="item.vehicle_no"
                  class="form-control h-40px mr-3"
                  placeholder="Vehicle number"
                />
                <a
                  href="#"
                  :class="{ disabled: item.status === 3 || item.status === 0 }"
                  class="h-40px w-50px btn btn-icon btn-light btn-hover-light btn-sm ml-1"
                  @click="() => dispatchVehicle(item)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <v-icon size="20">mdi-step-forward</v-icon>
                  </span>
                </a>
                <a
                  href="#"
                  :class="{ disabled: item.status !== 3 }"
                  class="h-40px w-50px btn btn-icon btn-light btn-hover-light btn-sm ml-1"
                  @click="() => printVehicle(item)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <v-icon size="20">mdi-printer</v-icon>
                  </span>
                </a>
                <a
                  href="#"
                  :class="{ disabled: item.status > 1 }"
                  class="h-40px w-50px btn btn-icon btn-light btn-hover-light btn-sm ml-1"
                  @click="() => handleRemove(index, item.status)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <v-icon size="20">mdi-delete</v-icon>
                  </span>
                </a>
              </div>
            </div>
          </template>
        </div>
        <div class="d-flex justify-end my-1">
          <a
            class="w-130px py-3 px-2 float-right btn btn-sm btn-primary"
            @click="handleAttach"
          >Add more Vehicle</a
          >
        </div>
        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button class="btn btn-light mr-3 px-5 py-3 ls1" @click="toggleModal">
            Close
          </button>
          <button class="btn btn-info px-5 py-3 ls1" @click="submitEditForm">
            Update
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import SwalService from "@/core/services/swal.service";
export default {
  name: "VehicleCRUDModal",
  // components: { QueueUpload },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    vehicles: [],
    // errorState: [false],
    count: 0,
    serverData: null,
  }),
  methods: {
    handleAttach() {
      this.vehicles.push({ id: null, vehicle_no: "", status: 0 });
      this.$forceUpdate();
      // this.errorState.push(false);
    },
    async removeVehicleFromServer(id) {
      let data = {
        id: this.$store.getters.getItemForAction.id.id,
        order_vehicle_id: id,
      };
      await ApiService.post(
        "/fulfillment/orders/delete_order_vehicle",
        data
      ).then(() => {
        SwalService.successMessage({
          html: "Successfully removed",
        });
      });
    },
    dispatchVehicleFromServer(id) {
      let data = {
        id: this.$store.getters.getItemForAction.id.id,
        order_vehicle_id: id,
      };
      this.pageLoader(true);
      ApiService.post("/fulfillment/orders/close_dispatch", data)
        .then(() => {
          SwalService.successMessage({
            html: SwalService.messages.successifully_completed,
          });
          this.loadDataFromServer();
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    dispatchVehicle(vehicle) {
      if (vehicle.status === 3 && vehicle.status === 0) {
        return;
      }

      SwalService.warningConditionMessage(
        { html: "Do you want to close dispatch?" },
        () => {
          this.dispatchVehicleFromServer(vehicle.id);
        }
      );
    },
    getPrintVehicleFile(id) {
      let data = {
        id: this.$store.getters.getItemForAction.id.id,
        order_vehicle_id: id,
      };
      this.pageLoader(true);
      ApiService.post("/fulfillment/orders/print_delivery_note", data)
        .then((res) => {
          const link = document.createElement("a");
          link.href = res.data.path;
          link.target = "_blank";
          link.download = "orders.csv";
          link.click();
          SwalService.successMessage({
            html: SwalService.messages.successifully_completed,
          });
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    printVehicle(vehicle) {
      if (vehicle.status !== 3) {
        return;
      }
      this.getPrintVehicleFile(vehicle.id);
    },
    handleRemove(index, status) {
      // document.getElementById(`fileInput${index}`).click();
      if (status > 1) {
        return;
      }
      if (this.vehicles.length !== 0) {
        if (this.vehicles[index]["id"]) {
          this.pageLoader(true);
          this.removeVehicleFromServer(this.vehicles[index]["id"])
            .then(() => {
              this.vehicles.splice(index, 1);
              this.pageLoader(false);
            })
            .catch(() => {
              this.pageLoader(false);
            });
        } else {
          this.vehicles.splice(index, 1);
        }
        // this.errorState.splice(index, 1);
      }
    },
    async toggleModal() {
      if (!this.dialog) {
        await this.loadDataFromServer();
      } else {
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    async loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id.id };
      await ApiService.post("/fulfillment/orders/get_order_vehicles", data)
        .then((response) => {
          this.serverData = response.data;
          this.vehicles = JSON.parse(
            JSON.stringify(response.data.order_vehicles)
          );
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    async submitEditForm() {
      this.pageLoader(true);
      this.vehicles = this.vehicles.filter(
        (vehicle) => vehicle.vehicle_no !== "" && vehicle.vehicle_no != null
      );
      let data = {
        order_vehicles: this.vehicles,
        id: this.$store.getters.getItemForAction.id.id,
      };
      await ApiService.post("/fulfillment/orders/update_order_vehicles", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Vehicle data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.pageLoader(false);
          // this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },

    resetCreateForm() {
      this.vehicles = [];
      // this.errorState.splice(1);
      // this.$set(this.errorState, 0, false);
      this.serverData = null;
    },
  },
};
</script>
